<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>



        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-lg-12" v-if="isLoadingBlade">
                    <div class="d-flex justify-content-center">
                        <img :src="loadingBladeUrl" />
                    </div>
                </div>

                <div class="col-xl-12 order-xl-1" v-if="! isLoadingBlade">
                    <card shadow type="secondary">

                        <div slot="header" class="bg-white border-0" v-if="record">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">
                                        <em class="text-danger">{{ record && record.indexing_year ? record.indexing_year : '' }}</em> 
                                        STUDENT INDEXING APPLICATION 
                                        <em class="text-danger">
                                            {{ record && record.student && record.student.full_name ? 'From ' + record.student.full_name : '' }}
                                        </em>
                                    </h3>
                                </div>
                                <div class="col-4 text-right">
                                    <a href="#!" @click="$router.go(-1)" class="btn btn-sm btn-danger">
                                      <i class="fa fa-reply"></i> Back
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="pl-lg-4">
                            
                            <div v-if="! approvalPage">
                                <ProfileDetails
                                    :application="record"
                                    v-if="record"
                                />

                                <Documents
                                    :records="record.documents"
                                    v-if="record && record.documents.length > 0"
                                />

                                <Transaction
                                    :transaction="record.transaction"
                                    v-if="record && record.transaction"
                                />

                                <div v-if="((record.status == 'submitted') && (record.payment_status == 'paid'))">
                                    <base-button
                                        type="danger" size="lg"
                                        @click="showApprovalPage('reject')"
                                    >
                                        <i class="fa fa-ban"></i> Reject
                                    </base-button>

                                    <base-button
                                        type="success" size="lg"
                                        class="right"
                                        @click="showApprovalPage('approve')"
                                    >
                                        <i class="fa fa-check"></i> Approve
                                    </base-button>
                                </div>
                            </div>

                            <div v-if="approvalPage">

                                <!-- Import Approve Application template here -->
                                <ApproveApplication
                                    :application="record"
                                    :loadApplication="loadApplication"
                                    :hideApprovePage="hideApprovePage"
                                    v-on:hideApprovePage="hideApprovePage"
                                    v-if="pageType == 'approve'"
                                />

                                <!-- Import Reject Application template here -->
                                <RejectApplication
                                    :application="record"
                                    :loadApplication="loadApplication"
                                    v-on:hideApprovePage="hideApprovePage"
                                    v-if="pageType == 'reject'"
                                />

                            </div>


                            <div class="col-sm-12 text-center">
                                <base-alert :type="errorType" v-if='showErrorStatus'>
                                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                    <span class="alert-inner--text">
                                        {{ errorMsg }}
                                    </span>
                                </base-alert>
                            </div>

                        </div>

                    </card>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import moment from 'moment';
    import ProfileDetails from './ProfileDetails.vue';
    import Documents from './Documents.vue';
    import Transaction from './Transaction.vue';
    import ApproveApplication from './ApproveApplication.vue';
    import RejectApplication from './RejectApplication.vue';

    export default {
        name: 'ApplicationDetail',
        components: {
            ProfileDetails,
            Documents,
            Transaction,
            ApproveApplication,
            RejectApplication
        },
        data() {
            return {
                record: {},
                paginatedItems: [],
                errorType: null,
                showErrorStatus: false,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true,
                approvalPage: false,
                pageType: null
            }
        },
        filters: {
            getDateFormat(value) {
                if (value) {
                    return moment(value).format('Do MMM, YYYY');
                } else {
                    return 'N/A';
                }
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.')
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' NGN'
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getStudentIndexingApplication']),
            loadApplication() {// load insurance details
                this.showErrorStatus = false;
                let data = { id: this.$route.params.id };
                this.getStudentIndexingApplication(data).then((res) => {// get indexing record
                    if(res.status) {
                        this.record = res.application;
                    } else {
                        return window.location.href = '/';
                    }
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            showApprovalPage(type) {// show approval/reject page
                this.approvalPage = true;
                this.pageType = type;
            },
            hideApprovePage() {// hide approve page
                this.approvalPage = false;
                this.pageType = null;
            },
            getStatusColor(status) {
                if(status == 'approved') {
                    return 'success';
                } else if(status == 'submitted') {
                    return 'warning';
                } else {
                    return 'danger';
                }
            }
        },
        created() {
            this.loadApplication();// load application
        },
    }
</script>
<style scoped>
    .right {
    float: right;
    }
</style>