
<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade">
            <div class="row">
                <div class="col-lg-2">
                    <base-input alternative="">
                        <select
                            class="form-control form-control-alternative"
                            v-model="pagination.perPage"
                            @change="getPageItems()"
                            :disabled="(records.length == 0)"
                        >
                            <option
                                v-for="number in entries"
                                :key="number"
                                :value="number"
                            >
                                {{ number + ' - Entries'}}
                            </option>
                        </select>
                    </base-input>
                </div>
                <div class="col-lg-1">
                    <base-input alternative="">
                        <select
                            class="form-control form-control-alternative"
                            v-model="page"
                            @change="getPageItems()"
                            :disabled="(records.length == 0)"
                        >
                            <option
                                v-for="number in pageNumbers"
                                :key="number"
                                :value="number"
                            >
                                {{ number }}
                            </option>
                        </select>
                    </base-input>
                </div>
                <div class="col-lg-3">
                    <base-input
                        placeholder="Search by Name / Matric No and hit enter"
                        class="input-group-alternative"
                        alternative=""
                        addon-right-icon="fas fa-search"
                        @change="searchRecords"
                        v-model="searchKey"
                        type="text"
                    >
                    </base-input>
                </div>
                <div class="col-lg-6">

                    <div class="dropdown right margin-round" v-if="records.length > 0">
                        <a 
                            class="btn btn-light btn-outline-success dropdown-toggle"
                            href="javascript:void(0)" 
                            role="button" 
                            data-toggle="dropdown" 
                            aria-expanded="false"
                            
                        >
                            <i class="fa fa-file-export"></i>
                            Export List
                        </a>

                        <div class="dropdown-menu">
                            <a 
                                class="dropdown-item" 
                                href="javascript:void(0)"
                                @click="exportIndexingList('excel')"
                            >
                                <i class="fas fa-file-excel"></i>
                                Excel Format
                            </a>
                            <!-- <a 
                                class="dropdown-item" 
                                href="javascript:void(0)"   
                                @click="exportIndexingList('pdf')"
                            >
                                <i class="fas fa-file-pdf"></i>
                                PDF Format
                            </a> -->
                        </div>
                    </div>
                    
                    <button
                        :class="'btn btn-light btn-outline-'+ (showFilters ? 'danger' : 'primary') +' right'"
                        @click="displayFilters()"
                        v-if="! isFiltered"
                    >
                        <i :class="'fa fa-'+(showFilters ? 'times' : 'filter')"></i>
                        {{ showFilters ? 'Remove Pane' : 'Filter List'}}
                    </button>

                    <button
                        :class="'btn btn-light btn-outline-danger right'"
                        @click="loadIndexingApplications()"
                        v-if="isFiltered"
                    >
                        <i class="fa fa-times"></i>
                        Remove Filters
                    </button>
                </div>
            </div>
        </div>


        <div v-if="showFilters && ! isLoadingBlade">
                <!-- <h4 class="mb-0 text-uppercase text-center"></h4> -->
                <hr v-if="! isFiltered">
                <div class="row" v-if="! isFiltered">               

                    <div class="col-lg-3">
                        <base-input alternative=""
                            label="Indexing Year"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.indexing_year"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-for="year in years"
                                    :value="year"
                                    :key="year"
                                >
                                    {{year}}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-3">
                        <base-input alternative=""
                            label="Level"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.level"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-for="level in levels"
                                    :value="level"
                                    :key="level"
                                >
                                    {{level}}
                                </option>
                            </select>
                        </base-input>
                    </div>


                    <div class="col-lg-12">
                        <button
                            class="btn btn-lg btn-info btn-block"
                            @click="applyFilters()"
                        >
                            <i class="fa fa-check"></i>
                            Apply Filters
                        </button>
                        <button
                            class="btn btn-lg btn-danger btn-block"
                            @click="displayFilters()"
                        >
                            <i class="fa fa-times"></i>
                            Remove Pane
                        </button>
                    </div>
                </div>
                <hr>
            </div>

        <!-- import records details template here -->
        <PendingApplicationsData
            :records="records"
            :pagination="pagination"
            :hasPagination="hasPagination"
            :paginate="paginate"
            :paginatedItems="paginatedItems"
            v-if="! isLoadingBlade"
        />

        <div class="col-sm-12 text-center" v-if='showErrorStatus'>
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import PendingApplicationsData from '../IndexingApplications/PendingApplications/PendingApplicationsData.vue';
    import swal from 'sweetalert2';// import sweet alert
    import axios from 'axios';
    import FileSaver from 'file-saver';

    export default {
        name: 'MdcnIndexingApplications',
        props: ['changeTitle', 'status'],
        components: {
            PendingApplicationsData
        },
        data() {
        return {
            records: [],
            paginatedItems: [],
            pagination: {
                total: null,
                prev_page_url: null,
                next_page_url: null,
                current_page: null,
                last_page: null,
                number: 1,
                from: 0,
                to: null,
                perPage: this.$store.state.url.perPage // get per page count
            },
            showErrorStatus: false,
            errorType: null,
            errorMsg: null,
            isLoadingBlade: true,
            searchKey: '',
            page: 1,
            showFilters: false,
            isFiltered: false,
            filters: {
                indexing_year: null,
                level: null
            }
        }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'getExportIndexingListUrl']),
            hasPagination() {
                return this.pagination.next_page_url || this.pagination.prev_page_url;
            },
            pageNumbers() {
                let start = 1;
                let end = this.pagination.last_page;
                let pages = [];
                for (let i = start; i <= end; i++ ) {
                    pages.push(i);
                }
                return pages;
            },
            years() {
                let startYear = 2019;// start from 1900
                let currentYear = parseInt(moment().format('YYYY')) + 1;
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            },
            levels() {
                return [ 200, 300, 400, 500, 600 ];
            },
            entries() {
                return [ 20, 50, 100];
            }
        },
        methods: {
            ...mapActions(['getIndexingApplications', 'getPaginatedExactApplications', 'getFilteredIndexingApplications']),
            applyFilters() {// apply filters function
                let status = this.validFilterInputs();
                this.showErrorStatus = false;
                if(status) {
                    this.preloader();// show loading
                    let data = { 
                        id: this.$store.state.user.schoolId, 
                        limit : this.pagination.perPage, 
                        status: this.status, 
                        filters : JSON.stringify(this.filters)
                    };
                    this.getFilteredIndexingApplications(data).then((res) => {// get applications
                        this.setPages(res.indexings);
                        this.isFiltered = true;                        
                    }).
                    catch(() => {
                        this.records = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    let msg = 'Kindly select one of the filter actions, and try again';
                    swal.fire('Invalid Selection', msg, 'warning');
                }
            },
            validFilterInputs() {
                let data = this.filters;
                return data.indexing_year || data.level;
            },
            loadIndexingApplications() {
                this.showErrorStatus = false;
                let data = { limit : this.pagination.perPage, id: this.$store.state.user.schoolId, status : this.status };
                this.getIndexingApplications(data).then((res) => {// get records
                    this.setPages(res.indexings);
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.records = [];
                this.paginatedItems = res.data;
                this.records = this.paginatedItems;
                this.pagination.from = res.from;
                this.pagination.to = res.to;
                this.pagination.total = res.total;
                this.pagination.current_page = res.current_page;
                this.pagination.last_page = res.last_page;
                this.pagination.prev_page_url = res.prev_page_url;
                this.pagination.next_page_url = res.next_page_url;
                this.isLoadingBlade = false;
                this.emptyRecordsMsg();//show empty msg  
                this.showFilters = false;
                this.isFiltered = false;
                swal.close();   
                this.changeTitle('MDCN-' + this.status + ' Student Indexing Applications', true);// change title 
            },
            paginate() {// paginate function
                this.showErrorStatus = false;
                let url = (this.pagination.number > this.pagination.current_page) ?
                    this.pagination.next_page_url : this.pagination.prev_page_url;
                if(url) {// make api call
                    this.isLoadingBlade = true
                    this.getPaginatedExactApplications(url).then((res) => {// get records
                        this.pagination.number = res.indexings.current_page;
                        this.setPages(res.indexings);
                    }).
                    catch(() => {
                        this.records = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.records = this.paginatedItems;
                }
            },
            emptyRecordsMsg() {
                return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Applications Yet!!!';
            },
            getPageItems() {
                let url = this.pagination.next_page_url ? this.pagination.next_page_url : this.pagination.prev_page_url;
                if(url) {
                    let exploded = url.split('&');
                    let newUrl = exploded[0]+'&'+exploded[1]+'&limit='+this.pagination.perPage+'&page='+this.page;
                    this.isLoadingBlade = true
                    this.getPaginatedExactApplications(newUrl).then((res) => {// get records
                        this.pagination.number = res.indexings.current_page;
                        this.setPages(res.indexings);
                    }).
                    catch(() => {
                        this.records = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                }                
            },
            searchRecords() {
                this.showErrorStatus = false;
                let key = this.searchKey.toLowerCase();
                if(key != '' ) {
                    this.isLoadingBlade = true;
                    let data = { key : key, id: this.$store.state.user.schoolId, status : this.status };
                    this.records = [];
                    this.getIndexingApplications(data).then((res) => {// get records
                        this.isLoadingBlade = false;
                        this.records = res.indexings;
                        if(this.records.length == 0) {
                            this.showErrorStatus = true;
                            this.errorType = 'warning';
                            this.errorMsg = 'No Matched Record!!!';
                        }
                    }).
                    catch(() => {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.records = this.paginatedItems;
                    this.emptyRecordsMsg();
                }
            },
            displayFilters() {// diplay filters template
                this.emptyFilters();// empty filters
                this.isFiltered = false;
                this.showFilters = ! this.showFilters;
                ! this.showFilters ? this.loadIndexingApplications() : false;
            },
            exportIndexingList(type) {// export student Indexed list function
                this.preloader();
                axios.post(this.getExportIndexingListUrl, {
                    option: type,
                    id: this.$store.state.user.schoolId,
                    status: this.status
                }, {
                    responseType: 'blob'
                }).then((response) => {
                    if(response.status == 200) {                        
                        FileSaver.saveAs(response.data, 'student-indexed-list.xlsx'); // download file
                        swal.close();
                    } else {
                        swal.fire("Server Failure", "File couldn't download, try again...", "warning");
                    }                
                });
            },
            emptyFilters() {
                return this.filters = { indexing_year: null, level: null };
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.loadIndexingApplications();// load applications
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .margin-round {
        margin: 0 6px;
    }
</style>
