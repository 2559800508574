<template>
    <div class="row">
        <div class="col-lg-12">
            <h3 class="text-uppercase text-center" v-html="title"></h3>
            <hr>
        </div>

        <div class="col-lg-12" v-if="students && students.length > 0">
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="students"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th>Full Name <br> (surname last)</th>
                            <th>Email Address <br>/ Gender</th>
                            <th>Matriculation number <br>/ Year of admission</th>
                            <th>Index Number</th>
                            <!-- <th>List Status</th> -->
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ index+1 }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.full_name ? row.full_name : 'N/A' }}
                        </td>
                        <td>
                            {{ row.email ? row.email : 'N/A'  }} <br>
                            <badge type="primary">
                                {{ row.gender ? row.gender : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            {{ row.matric_no ? row.matric_no : 'N/A' }}<br>
                            <badge type="primary">
                                {{ row.admission_year ? row.admission_year : 'N/A' }}
                            </badge>
                        </td>
                        <td>{{ row.index_no ? row.index_no : 'N/A'  }}</td>
                        <td>
                            <base-button
                                type="info" size="sm"
                                @click="$emit('editRecord', row)"
                            >
                                <i class="fa fa-pen"></i> Edit
                            </base-button>
                        </td>
                        </template>

                    </base-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Badge from '../../../components/Badge.vue';

    export default {
        components: { Badge },
        name: 'IndexingStudentData',
        props: ["students"],
        filters: {
            getDateFormat(value) {
                return moment(value).format('MMM, YYYY');
            }
        },
        computed: {
            title() {
                let student = this.students && this.students.length > 0 ? this.students[0] : false;
                let text = "<em class='text-danger'>" + (student && student.full_name ? student.full_name : '');
                text += '</em>\'s Student Record';
                return text;
            }
        }
    }
</script>