<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase" v-html="title"></h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/indexing" 
                                        class="btn btn-sm btn-danger"
                                    >
                                        <i class="fa fa-arrow-left"></i>
                                        Back to Indexing
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade">
                            <!-- import School Indexing template here -->
                            <IndexingApplications
                                :changeTitle="changeTitle"
                                v-if="code && code == 'applications'"
                            />

                            <!-- import MDCN Approved Indexing template here --> 
                            <MdcnIndexingApplications
                                :changeTitle="changeTitle"
                                status="approved"
                                v-if="code && code == 'approved'"
                            />

                            <!-- import MDCN Reject Indexing template here --> 
                            <MdcnIndexingApplications
                                :changeTitle="changeTitle"
                                status="rejected"
                                v-if="code && code == 'rejected'"
                            />

                            <!-- import Search Indexing template here -->
                            <SearchIndexingStudent
                                :changeTitle="changeTitle"
                                v-if="code && code == 'search'"
                            />
                            
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
    import IndexingApplications from './IndexingApplications/IndexingApplications.vue';
    import SearchIndexingStudent from './SearchIndexingStudent/SearchIndexingStudent.vue';
    import MdcnIndexingApplications from './MdcnIndexingApplications/MdcnIndexingApplications.vue';

    export default {
        name: 'IndexingDetails',
        components: {
            IndexingApplications,
            SearchIndexingStudent,
            MdcnIndexingApplications
        },
        data() {
        return {
            code: this.$route.params.code,
            title: null,
            isLoadingBlade: false
        }
        },
        methods: {
            loadTitle() {// load title
                return this.title = this.replaceString(this.code);
            },
            replaceString(value) {
                if(value) {
                    let text = value.replace('-', ' ');
                    return text.replace('-', ' ');
                }
                return 'Cpd Providers';
            },
            changeTitle(value, status) {// change title
                return status ? this.title = value : this.loadTitle();
            }
        },
        created() {
            this.loadTitle(); // load title
        }
    };
</script>
<style scoped>
</style>
