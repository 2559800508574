<template>
    <div>

        <hr>

        <div class="table-responsive">
                    
            <table class="table table-bordered">
                <tbody>
                    <td width="30%">
                        <h4>Applicant's Name:</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ application.student && application.student.full_name ? application.student.full_name : 'N/A' }}
                        </h4>
                    </td>
                </tbody>  

                <tbody>
                    <td width="30%">
                        <h4>Application Name:</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ application.application_type && application.application_type.name ? application.application_type.name : 'N/A' }}
                        </h4>
                    </td>
                </tbody>  
                <tbody>
                    <td width="30%">
                        <h4>Indexing Year:</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ application.indexing_year ? application.indexing_year : 'N/A' }}
                        </h4>
                    </td>
                </tbody>  

                <tbody v-if='showErrorStatus'>
                    <td colspan="2">
                        <base-alert :type="errorType">
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                        </base-alert>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        &nbsp;
                    </td>
                    <td>
                        <base-button 
                            size="lg"
                            type="info" 
                            @click="aprroveStudentApplication(application.id)"
                            v-if="! showBackButton"
                        >
                            <i class="fa fa-check"></i> 
                            Approve Application
                        </base-button>

                        <base-button 
                            size="lg"
                            type="danger" 
                            class="right"
                            @click="$emit('hideApprovePage')"
                            v-if="! showBackButton"
                        >
                            <i class="fa fa-times"></i> 
                            Cancel Approval
                        </base-button>

                        <base-button 
                            size="lg"
                            type="danger" 
                            @click="$emit('hideApprovePage')"
                            v-if="showBackButton"
                        >
                            <i class="fa fa-reply"></i> 
                            Back To Application
                        </base-button>
                    </td> 
                </tbody>

            </table>

        </div>

    </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'ApproveApplication',
    props: ['application', 'loadApplication'],
    data() {
      return {
        id: this.$route.params.id,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        showBackButton: false
      }
    },
    methods: {
      ...mapActions(['ApproveRejectApplication']),
        getFullName(profile) {
            let name = profile.first_name+' ';
            name += profile.other_name ? profile.other_name+' ' : '';
            name += profile.last_name;
            return name;
        },
        aprroveStudentApplication(id) {// approve application
            if(id) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeApproveApiCall(id);// make api call
                    }
                });
            } else {
                return this.showInuptError('Invalid Application');
            }             
        },
        makeApproveApiCall(id) {// reject application api
            let data = { id : id, status : 'school-approved', reject_reason : null };           
            this.preloader();// show loading
            this.ApproveRejectApplication(data).then((res) => {// reject application
                if(res.status) {
                    this.showBackButton = true;
                    this.reject_reason = null;
                    swal.fire('Application Approved!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadApplication(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });          
        },
        showInuptError(name) {
            this.showErrorStatus = true
            this.errorType = 'warning'
            this.errorMsg = 'Invalid ' + name + ' has been entered';
            var self = this;
            setTimeout(function() { self.showErrorStatus = false; }, 5000);
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    }   
  };
</script>
<style scoped>
.right {
  float: right;
}
.paddingLeft0 {
    padding-left: 0px;
}
.paddingRight0 {
    padding-right: 0px;
}
.paddingTop13 {
    padding-top: 13px;
}
</style>
