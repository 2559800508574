<template>
    <div class="row">
        <div class="col-lg-12" v-if="records && records.length > 0">
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%" v-if="showBatch">
                                <input
                                    class="mb-3"
                                    id="all-checked"
                                    type="checkbox"
                                    :checked="hasBatchApplicants"
                                    @change="updateRecords(null, true)"
                                />
                                <!-- :checked="checked" -->
                            </th>
                            <th width="2%">S/N</th>
                            <th>Student Name</th>
                            <th>Current Level / <br> Admission Year </th>
                            <th>Indexing  <br> Year</th>
                            <th>Application Date</th>
                            <th>Approved / <br> Rejected Date</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td v-if="showBatch">
                            <input
                                class="mb-3"
                                type="checkbox"
                                :id="'other-check'+row.id"
                                :checked="checkIfInList(row.id)"
                                @change="updateRecords(row)"
                            />
                            <!-- :checked="(row.seat_status == 'yes')" -->
                        </td>
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td>
                            {{ row.full_name ? row.full_name : 'N/A'  }} <br>
                            <badge type="dark">
                                {{ row.student && row.student.matric_no ? row.student.matric_no : 'N/A'  }}
                            </badge>
                        </td>
                        <td>
                            {{ row.level ? row.level : 'N/A'  }} <br>
                            <badge type="primary">
                                {{ row.student && row.student.admission_year ? row.student.admission_year : 'N/A'  }}
                            </badge>                            
                        </td>
                        <td>
                            {{ row.indexing_year ? row.indexing_year : 'N/A'  }}
                        </td>
                        <td>{{ row.application_date | getDateFormat }}</td>
                        <td>{{ row.approved_date | getDateFormat }}</td>
                        <td>
                            <router-link
                                :to="'/indexing/application/'+row.id"
                                class="btn btn-sm btn-success"
                            >
                                <i class="fa fa-eye"></i> Details
                            </router-link>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                    @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Badge from '../../../../components/Badge.vue';

    export default {
        components: { Badge },
        name: 'PendingApplicationsData',
        props: ["records", "pagination", "hasPagination", "paginate", "record", "showBatch", "applicants", "updateApplicantsBatchList"],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('D MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            hasBatchApplicants() {
                return this.applicants.length > 0;
            }
        },
        methods: {
            updateRecords(applicant, status = false) {
                if(status) {
                    this.updateApplicantsBatchList([], false);
                    if($('#all-checked').is(":checked")) {
                        for(let record of this.records) { 
                            this.updateApplicantsBatchList(record.id, true);
                        }
                    } else {
                        this.updateApplicantsBatchList([], false);
                    }
                } else {// eslint-disable-next-line
                    let checkStatus = $('#other-check'+applicant.id).is(":checked")
                    if(checkStatus) {
                        this.updateApplicantsBatchList(applicant.id, true);
                    } else {
                        if(this.applicants.length > 0) {
                            const applicants = this.applicants.filter(item => item != applicant.id);



                            // console.log(applicants);


                            this.updateApplicantsBatchList(applicants, false);
                        } else {
                            this.updateApplicantsBatchList(applicant.id, true);
                        }
                    }
                }
            },
            checkIfInList(id) {// check if applicant is in the list
                return this.applicants.includes(id);
            }
        }
    }
</script>