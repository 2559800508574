<template>
    <div>
        <template>
            <tabs fill class="flex-column flex-md-row">
                <card shadow>                                    

                    <tab-pane title="Pending Applications">
                        <span slot="title">
                            <i class="ni ni-books"></i>
                            Pending Indexing List
                        </span>
                        <!-- import Pending Applications template here -->
                        <PendingApplications
                            :changeTitle="changeTitle"
                            :status="'submitted'"
                        />
                    </tab-pane>

                    <tab-pane title="Rejected Applications">
                        <span slot="title">
                            <i class="ni ni-badge"></i>
                            Rejected Indexing List
                        </span>
                        <!-- import Rejected Applications template here -->
                        <PendingApplications
                            :changeTitle="changeTitle"
                            :status="'school-rejected'"
                        />
                    </tab-pane>

                    <tab-pane title="Completed Applications">
                        <span slot="title">
                            <i class="ni ni-archive-2"></i>
                            Completed Indexing List
                        </span>
                        <!-- import Completed Applications template here -->
                        <PendingApplications
                            :changeTitle="changeTitle"
                            :status="'school-approved'"
                        />
                    </tab-pane>

                </card>
            </tabs>

        </template>
    </div>
</template>
<script>
    import PendingApplications from './PendingApplications/PendingApplications';

    export default {
        name: 'IndexingApplications',
        props: ['changeTitle'],
        components: {
        PendingApplications
        }
    };
</script>
<style></style>
